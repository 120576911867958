<script>
import Vue from 'vue'

export default Vue.extend({
    data() {
        return {
            instances: [],
            newInstanceValue: '',
            showInstanceError: false,
        }
    },
    beforeMount() {
        this.instances = this.$sigma.getLocalInstances()
    },
    methods: {
        confirmInstanceName() {
            if (this.$sigma.validHostOrInstanceName(this.newInstanceValue)) {
                this.newInstanceLogin()
            } else {
                this.showInstanceError = true
            }
        },
        newInstanceLogin() {
            let instanceName = this.$sigma.hostnameToInstanceName(this.newInstanceValue)

            this.$router.push({ name: 'login', params: { instanceName } })
        },
        tokenIsValid(token) {
            let payload = this.$sigma.getTokenPayload(token)

            if (payload && payload.exp && new Date(payload.exp * 1000) > new Date()) {
                return true
            }
            return false
        },
        onNewInstanceFocus() {
            if (String(this.newInstanceValue).length < 1) {
                this.newInstanceValue = 'https://'
            }
        },
        onNewInstanceBlur() {
            if (String(this.newInstanceValue) === 'https://') {
                this.newInstanceValue = ''
            }
        },
    },
})
</script>
<template>
    <div class="home">
        <b-container>
            <b-row>
                <b-col md="6" offset-md="3">
                    <div class="mt-5">
                        <b-form @submit.prevent="confirmInstanceName">
                            <b-input-group>
                                <b-form-input
                                    v-model="newInstanceValue"
                                    size="lg"
                                    placeholder="Connect to a new instance"
                                    @focus="onNewInstanceFocus"
                                    @blur="onNewInstanceBlur" />
                                <b-input-group-append>
                                    <b-button variant="primary" type="submit">Connect</b-button>
                                </b-input-group-append>
                            </b-input-group>
                        </b-form>
                    </div>
                </b-col>
            </b-row>

            <b-row>
                <b-col md="4" offset-md="4">
                    <b-list-group class="mt-1">
                        <b-list-group-item
                            v-for="(instanceData, hostname) in instances"
                            :key="hostname"
                            class="d-flex justify-content-between align-items-center">
                            <router-link :to="{ name: 'appHome', params: { instanceName: $sigma.hostnameToInstanceName(hostname) } }">{{
                                $sigma.hostnameToInstanceName(hostname)
                            }}</router-link>
                            <b-badge v-if="tokenIsValid(instanceData.token)" variant="primary" pill>Logged In</b-badge>
                        </b-list-group-item>
                    </b-list-group>
                </b-col>
            </b-row>
        </b-container>
        <b-modal v-model="showInstanceError" title="Instance Warning" cancel-title="Go Back" ok-title="Continue" @ok="newInstanceLogin">
            <p class="my-4">{{ newInstanceValue }} may not be a valid instance name.</p>
        </b-modal>
    </div>
</template>
